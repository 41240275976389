import * as Sentry from '@sentry/react';
import { ExtraErrorData } from '@sentry/integrations';
import { SENTRY_DSN, VERSION } from './constants/rails';

Sentry.init({
  dsn: SENTRY_DSN,
  release: VERSION,
  environment: process.env.RAILS_ENV,
  normalizeDepth: 6,
  enabled: true,
  tunnel: '/tunnel',
  // integrations: [new ExtraErrorData()],
  integrations(integrations) {
    const filteredIntregrations = integrations.filter(integration => integration.name !== 'Dedupe');

    return [...filteredIntregrations, new ExtraErrorData()];
  },
});
